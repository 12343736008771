<template>
  <v-container fluid>
    <v-card>
      <v-card-title
        class="mb-0 pb-0"
      >
        <v-row
          justify="center"
        >
          <v-col
            cols="12"
            md="7"
          >
            Lista de Requição
          </v-col>
          <v-col
            cols="12"
            md="2"
            :class="{'d-flex justify-end' : !isMobile}"
          >
            <v-btn
              color="blue"
            >
              <v-icon class="pr-3">
                mdi-archive-plus
              </v-icon>
              Nova Requisição
            </v-btn>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="valorStatus"
              :items="itemsStatus"
              label="Situação"
              item-text="texto"
              item-value="valor"
              solo
              @change="onCarregarConsulta"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        dense
        :headers="headers"
        :items="listaRequisicao"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                small
                v-bind="attrs"
                color="blue"
                @click="onAtender(item)"
                v-on="on"
              >
                mdi-cart-plus
              </v-icon>
            </template>
            <span>Editar</span>
          </v-tooltip>
          <v-tooltip
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                color="red"
                v-bind="attrs"
                class="ml-3"
                @click="onCancelar(item)"
                v-on="on"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>Excluir</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <!-- <v-dialog
      v-model="dialogExpedicao"
      persistent
      max-width="800"
    >
      <modelExpedicao
        v-if="dialogExpedicao"
        :pedido="pedido"
        @onSairExpedicao="onSairExpedicao"
        @onSucessoExpedicao="onSucessoExpedicao"
      />
    </v-dialog> -->
  </v-container>
</template>
<script>
  import requisicaoRep from '../../../components/services/almox/requisicaoRepository'
  // import transacaoRep from '../../../components/services/transacaoRepository'
  // COMPONENTES
  // import modelExpedicao from '../../componentes/componente-expedicao.vue'
  // import modelNovaRequisicao from './novaRequisicao.vue'
  export default {
    // components: {
    //   modelExpedicao,
    // },
    data () {
      return {
        headers: [
          {
            text: 'Pedido',
            align: 'start',
            value: 'cdRequisicao',
            width: 80,
          },
          {
            text: 'Dt. Requisição',
            align: 'center',
            value: 'dtRequisicao',
            width: 200,
          },
          {
            text: 'Requisitante',
            align: 'start',
            value: 'cdUsuario',
            width: 200,
          },
          { text: '', value: 'actions', width: 100, sortable: false },
        ],
        itemsStatus: [
          {
            texto: 'Esperando',
            valor: 'N',
          },
          {
            texto: 'Parcial',
            valor: 'P',
          },
          {
            texto: 'Finalizado',
            valor: 'A',
          },
        ],
        valorStatus: 'N',
        listaRequisicao: [],
        pedido: {},
        dialogExpedicao: false,
        windowWidth: window.innerWidth,
        isMobile: false,
      }
    },
    async created () {
      this.dataSelecionada = this.formatDate(this.dataAtual)
      this.onCarregarConsulta()
      // this.getTransacao()
      if (this.$store.getters.usuario.tpusuario !== 'A') {
        await this.getTransacao()
      }
      this.isMobile = window.innerWidth < 600
    },
    methods: {
      onDataSelecionada (data) {
        this.dataSelecionada = data
        this.onCarregarConsulta()
      },
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}-${month}-${year}`
      },
      async onCarregarConsulta () {
        this.$store.commit('setSplashScreen', true)
        this.pamentro = {
          status: this.valorStatus,
        }
        await requisicaoRep.get(this.pamentro).then(response => {
          this.$store.commit('setSplashScreen', false)
          this.listaRequisicao = response.data.dados
          //   if (response.data.status === 'sucesso') {
          //     this.$store.commit('setSplashScreen', false)
          //     this.listaPedido = response.data.dados
          //   } else if (response.data.status === 'mensagem') {
          //     this.$store.commit('setSplashScreen', false)
          //     this.$swal({
          //       icon: 'warning',
          //       text: response.data.dados,
          //     })
          //   } else if (response.data.status === 'error') {
          //     this.$store.commit('setSplashScreen', false)
          //     this.$swal({
          //       icon: 'error',
          //       text: response.data.messagem,
          //     })
          //   } else {
          //     this.$swal({
          //       icon: 'error',
          //       text: response.data.mensagemErro,
          //     })
          //     this.$store.commit('setSplashScreen', false)
          //   }
          // console.log(response)
        }).catch(error => {
          this.$store.commit('setSplashScreen', false)
          this.$swal({
            icon: 'error',
            text: error,
          })
        })
      },
      async onAtender () {
      },
      async onCancelar () {
      },
    },
  }
</script>
