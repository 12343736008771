import Repository from '../repository'

const resource = '/almox/requisicao'

export default {

  get (data) {
    return Repository.get(`${resource}`, { params: data })
  },
}
